import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, Textarea, Message, Toggle } from "@zendeskgarden/react-forms";
import { Option, Combobox, Field, type IComboboxProps } from "@zendeskgarden/react-dropdowns";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "urql";
import { Button } from "@zendeskgarden/react-buttons";
import uniqBy from "lodash/uniqBy";
import { SortHelper } from "@linear/common/utils/SortHelper";
import {
  CreateAttachmentMutation,
  CreateIssueMutation,
  type ICreateAttachmentMutation,
  type ICreateIssueMutation,
  type ITeamQuery,
  type IViewerQuery,
  TeamQuery,
} from "../queries";
import type { Label, Team, TemplateData, User, ZendeskTicket } from "../types";
import { Constants } from "../constants";
import { attachmentForZendeskConversation } from "../utils/formattingUtils";

const NewIssueSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  team: Yup.string().required("Required"),
  priority: Yup.number(),
  assignee: Yup.string(),
  label: Yup.string(),
});

type Props = {
  title?: string;
  description?: string;
  templateData?: TemplateData;
  ticket: ZendeskTicket;
  teams: Team[];
  priorities: IViewerQuery["issuePriorityValues"];
  onCreate(): void;
  onCancel(): void;
};

type Priority = IViewerQuery["issuePriorityValues"][0];

interface FormValues {
  title: string;
  description: string;
  includeMessage: boolean;
  team: string;
  priority?: number;
  assignee?: string;
  labels: string[];
}

export function CreateForm(props: Props) {
  const { ticket, teams, priorities, templateData, onCreate, onCancel } = props;
  const { id: templateId } = templateData || {};
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const [initialTeamId] = React.useState(
    templateData?.teamId || localStorage.getItem(Constants.teamCacheKey) || teams[0]?.id
  );
  const [includeMessage] = React.useState<boolean>(
    localStorage.getItem(Constants.includeMessagePreferenceKey) === "true"
  );
  const [createIssueResult, createIssue] = useMutation<ICreateIssueMutation>(CreateIssueMutation);
  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const fetching = createIssueResult.fetching || createAttachmentResult.fetching;
  const quotedMessage = React.useMemo(
    () =>
      ticket.messages[0]?.body
        .trim()
        .split("\n")
        .map(row => `> ${row}`)
        .join("\n"),
    [ticket]
  );

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (fetching) {
      setSubmitting(false);
      return;
    }

    localStorage.setItem(Constants.teamCacheKey, values.team);
    const issueResult = await createIssue({
      teamId: values.team,
      title: values.title,
      description: values.description,
      assigneeId: values.assignee,
      labelIds: values.labels || [],
      priority: values.priority,
      templateId,
    });
    const issue = issueResult.data?.issueCreate?.issue;
    if (issue) {
      await createAttachment({ ...attachmentForZendeskConversation(ticket), issueId: issue.id });
      setSubmitting(false);
      onCreate();
    }
  };

  function handleIncludeMessage(form: FormikProps<FormValues>, checked: boolean) {
    if (checked) {
      void form.setFieldValue("description", `${quotedMessage}\n\n${form.values.description}`);
    } else if (form.values.description.startsWith(quotedMessage)) {
      void form.setFieldValue("description", form.values.description.replace(quotedMessage, "").trim());
    }
    localStorage.setItem(Constants.includeMessagePreferenceKey, checked.toString());
  }

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          title: props.title || templateData?.title || ticket.subject || "",
          includeMessage,
          description: props.description || templateData?.description || (includeMessage ? quotedMessage : ""),
          team: initialTeamId,
          labels: templateData?.labelIds || [],
          assignee: templateData?.assigneeId,
          priority: templateData?.priority,
        }}
        validationSchema={NewIssueSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<FormValues>) => (
          <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
            <Field>
              <Field.Label>Title</Field.Label>
              <FormikField name="title">
                {({ field }: FieldProps<string, FormValues>) => (
                  <>
                    <Input isCompact autoFocus {...field} />

                    {form.submitCount > 0 && form.touched.title && form.errors.title && (
                      <Message validation="error">{form.errors.title}</Message>
                    )}
                  </>
                )}
              </FormikField>
            </Field>
            <Field>
              <Field.Label>Description</Field.Label>
              <FormikField name="description">
                {({ field }: FieldProps<string, FormValues>) => (
                  <Textarea minRows={4} maxRows={10} isCompact {...field} />
                )}
              </FormikField>
            </Field>
            <Field>
              <FormikField name="includeMessage" type="checkbox">
                {({ field }: FieldProps<string, FormValues>) => (
                  <Toggle
                    id="includeMessage"
                    {...field}
                    onChange={e => {
                      handleIncludeMessage(form, e.target.checked);
                      field.onChange(e);
                      textareaRef.current?.focus();
                    }}
                  >
                    <Field.Label htmlFor="includeMessage" isRegular>
                      Include message
                    </Field.Label>
                  </Toggle>
                )}
              </FormikField>
            </Field>

            <FormikField name="team">
              {({ field }: FieldProps<string, FormValues>) => {
                const selectedTeam = field.value ? teams.find(team => team.id === field.value) : undefined;
                return (
                  <FilteredCombobox
                    label="Team"
                    options={teams}
                    nameForOption={(team: Team) => team.displayName}
                    onSelect={teamId => {
                      void form.setFieldValue("team", teamId);
                    }}
                    selectionValue={selectedTeam?.id}
                  >
                    {({ matchingOptions }) =>
                      matchingOptions.map((team: Team) => (
                        <Option
                          key={team.id}
                          value={team.id}
                          label={team.displayName}
                          isSelected={team.id === field.value}
                        />
                      ))
                    }
                  </FilteredCombobox>
                );
              }}
            </FormikField>

            <FormikField name="priority">
              {({ field }: FieldProps<number, FormValues>) => {
                const selectedPriority = field.value
                  ? priorities.find(priority => priority.priority === field.value)
                  : undefined;
                return (
                  <FilteredCombobox
                    label="Priority"
                    options={priorities}
                    nameForOption={(priority: Priority) => priority.label}
                    onSelect={priority => {
                      void form.setFieldValue("priority", Number(priority));
                    }}
                    selectionValue={String(selectedPriority?.priority)}
                  >
                    {({ matchingOptions }) =>
                      matchingOptions.map((priority: Priority) => (
                        <Option
                          key={priority.priority}
                          value={String(priority.priority)}
                          label={priority.label}
                          isSelected={priority.priority === field.value}
                        />
                      ))
                    }
                  </FilteredCombobox>
                );
              }}
            </FormikField>

            <TeamFormFields form={form} teamId={form.values.team} key={form.values.team} />

            <Buttons>
              <Button isPrimary type="submit" disabled={form.isSubmitting}>
                {form.isSubmitting ? "Creating…" : "Create issue"}
              </Button>
              <Button isBasic onClick={onCancel}>
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 4px;
`;

const StyledForm = styled(Form)`
  > div {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  padding-top: 12px;
  background-color: ${props => props.theme.colors.background};

  > button:first-child {
    margin-right: 12px;
  }
`;

// -- Team related form fields

const TeamFormFields = ({ teamId, form }: { form: FormikProps<FormValues>; teamId?: string }) => {
  const [allLabels, setAllLabels] = useState<Label[]>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);

  const [result] = useQuery<ITeamQuery>({
    query: TeamQuery,
    variables: { teamId, after: cursor },
    pause: teamId === undefined || !hasMore,
  });

  const { data, fetching, error } = result;
  const users = result.data?.team?.members.nodes || [];
  const labels = result.data?.team?.labels.nodes || [];

  // Effect to handle new data and trigger next page fetch
  useEffect(() => {
    if (!fetching && data && hasMore) {
      const labelsPageInfo = data.team?.labels?.pageInfo;

      // Update our accumulated list
      setAllLabels(prev => uniqBy([...prev, ...labels], "id"));

      // Check if there are more pages
      if (labelsPageInfo?.hasNextPage) {
        // Trigger fetch of next page
        setCursor(labelsPageInfo.endCursor);
      } else {
        // We've reached the end
        setHasMore(false);
        // Update the form to remove labels from previous team
        void form.setFieldValue(
          "labels",
          form.values.labels.filter(label => [...allLabels, ...labels].find(l => l.id === label))
        );
      }
    }
  }, [data, fetching, hasMore]);

  // Handle errors
  useEffect(() => {
    if (error) {
      setHasMore(false);
    }
  }, [error]);

  // filter labels to not include groups and sort by name
  const labelGroups = allLabels.filter(label => labels.find(l => l.parent?.id === label.id));
  const rootLabels = allLabels.filter(label => !labelGroups.includes(label));

  const labelOptions = SortHelper.natural(rootLabels, label => `${label.parent?.name || ""} ${label.name}`).map(
    label => ({
      ...label,
      name: label.parent ? `${label.parent.name} → ${label.name}` : label.name,
    })
  );

  if (result.fetching || hasMore) {
    // Render dummy fields to avoid layout shift and a bug where the Combobox options will not re-render when the query
    // returns.
    return (
      <>
        <Field>
          <Field.Label>Assignee</Field.Label>
          <Combobox isAutocomplete={true} isCompact={true} maxHeight="auto"></Combobox>
        </Field>
        <Field>
          <Field.Label>Labels</Field.Label>
          <Combobox isAutocomplete={true} isCompact={true} maxHeight="auto"></Combobox>
        </Field>
      </>
    );
  }

  return (
    <>
      <FormikField name="assignee">
        {({ field }: FieldProps<string, FormValues>) => {
          const assignee = field.value && users ? users.find(user => user.id === field.value) : undefined;
          return (
            <FilteredCombobox
              label="Assignee"
              options={SortHelper.natural(users, "name")}
              nameForOption={(user: User) => user.name}
              onSelect={userId => {
                void form.setFieldValue("assignee", userId);
              }}
              selectionValue={assignee?.id ?? ""}
            >
              {({ matchingOptions }) =>
                matchingOptions.map((user: User) => (
                  <Option key={user.id} value={user.id} label={user.name} isSelected={user.id === field.value} />
                ))
              }
            </FilteredCombobox>
          );
        }}
      </FormikField>
      <FormikField name="labels">
        {({ field }: FieldProps<string, FormValues>) => {
          const selectedLabels = field.value
            ? labelOptions.filter(label => field.value.includes(label.id)).map(label => label.id)
            : [];
          return (
            <FilteredCombobox
              label="Labels"
              options={labelOptions}
              multiSelect={true}
              nameForOption={(label: Label) => label.name}
              onSelect={(labelIds: string[]) => {
                void form.setFieldValue(
                  "labels",
                  // Enforce unique labels within groups. Reverse ensures that the last selected label is the one that
                  // is retained.
                  uniqBy(labelIds.reverse(), labelId => {
                    const label = labelOptions.find(l => l.id === labelId);
                    return label?.parent?.id || labelId;
                  }).reverse()
                );
              }}
              selectionValue={selectedLabels}
            >
              {({ matchingOptions }) =>
                matchingOptions.map((label: Label) => (
                  <Option
                    key={label.id}
                    value={label.id}
                    label={label.name}
                    isSelected={field.value.includes(label.id)}
                  />
                ))
              }
            </FilteredCombobox>
          );
        }}
      </FormikField>
    </>
  );
};

type FilteredComboboxProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children(renderProps: { matchingOptions: any[] }): React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nameForOption(model: any): string;
  /** The callback to call when an option is selected */
  onSelect(option: string | string[] | null): void;
  /** The label of the combobox */
  label: string;
  /** Whether the combobox is multi-select */
  multiSelect?: boolean;
  /** The selection value of the combobox */
  selectionValue?: string | string[] | null;
};

const FilteredCombobox = (props: FilteredComboboxProps) => {
  const { options, nameForOption, children, label } = props;
  const [input, setInput] = React.useState<string>("");
  const [matchingOptions, setMatchingOptions] = React.useState(options);

  React.useEffect(() => {
    if (!input || input.length === 0) {
      setMatchingOptions(options);
      return;
    }

    const matchedOptions = options.filter(
      option => nameForOption(option).toLowerCase().indexOf(input.trim().toLowerCase()) !== -1
    );

    setMatchingOptions(matchedOptions);
  }, [options, input]);

  const handleChange: IComboboxProps["onChange"] = change => {
    if (change.type === "input:change") {
      setInput(change.inputValue ?? "");
    } else if (change.selectionValue) {
      props.onSelect?.(change.selectionValue);
    }
  };

  return (
    <Field>
      <Field.Label>{label}</Field.Label>
      <Combobox
        isAutocomplete={true}
        isCompact={true}
        defaultActiveIndex={0}
        maxHeight="auto"
        isMultiselectable={!!props.multiSelect}
        selectionValue={props.selectionValue}
        onChange={handleChange}
      >
        {matchingOptions.length > 0 ? (
          children({ matchingOptions })
        ) : (
          <Option isDisabled label="" value="No matches found" />
        )}
      </Combobox>
    </Field>
  );
};
