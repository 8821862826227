import type { User, Label as LabelModel, Attachment, Issue, Project, ProjectAttachment } from "./types";

// Shared Issue shape
const issueFragment = `
id
identifier
title
url
priorityLabel
createdAt
updatedAt
labels {
  nodes {
    id
    name
    color
  }
}
cycle {
  id
  number
  startsAt
  endsAt
  completedAt
}
project {
  name
}
team {
  id
  name
}
state {
  id
  name
  color
}
assignee {
  avatarUrl
  name
}
customerTicketCount
`;

/** Project fragment. */
const projectFragment = `
id
name
url
createdAt
updatedAt
teams {
  nodes {
    id
    name
    key
  }
}
status {
  id
  name
  color
}
priorityLabel
`;

/** Query to get a team and it’s members and labels. */
export const TeamQuery = `
  query ($teamId: String!, $after: String) {
    team(id: $teamId) {
      id
      name
      labels(first: 250, after: $after) {
        nodes {
          id
          name
          color
          parent {
            id
            name
            color
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      members(first: 250) {
        nodes {
          id
          name
          email
          avatarUrl
        }
      }
    }
  }
`;

/** TeamQuery shape. */
export interface ITeamQuery {
  team?: {
    id: string;
    name: string;
    labels: {
      nodes: LabelModel[];
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
    };
    members: {
      nodes: User[];
    };
  };
}

/** Query to search issues. */
export const SearchIssueQuery = `
  query ($searchQuery: String!, $includeComments: Boolean!) {
    searchIssues(term: $searchQuery, includeComments: $includeComments, first: 25) {
      nodes {
        ${issueFragment}
      }
    }
  }
`;

/** Query to search issues and projects. */
export const CombinedSearchQuery = `
  query ($searchQuery: String!, $includeComments: Boolean!) {
    searchIssues(term: $searchQuery, includeComments: $includeComments, first: 25) {
      nodes {
        ${issueFragment}
      }
    }
    searchProjects(term: $searchQuery, first: 25) {
      nodes {
        ${projectFragment}
      }
    }
  }
`;

/** CombinedSearchQuery shape. */
export interface ICombinedSearchQuery {
  searchIssues: {
    nodes: Issue[];
  };
  searchProjects: {
    nodes: Project[];
  };
}

/** Get AI generated ticket summary. */
export const TicketSummaryQuery = `
  query ($body: String!) {
    supportTicketSummary(body: $body) {
      title
      description
      success
    }
  }
`;
/** TicketSummaryQuery shape. */
export interface ITicketSummaryQuery {
  supportTicketSummary: {
    title?: string;
    description?: string;
    success: boolean;
  };
}

/** Create new issue mutation. */
export const CreateIssueMutation = `
  mutation IssueCreateMutation(
    $teamId: String!
    $title: String!
    $description: String
    $assigneeId: String
    $labelIds: [String!]
    $priority: Int
    $templateId: String
  ) {
    issueCreate(input: {
      teamId: $teamId
      title: $title
      description: $description
      assigneeId: $assigneeId
      labelIds: $labelIds
      priority: $priority
      templateId: $templateId
    }) {
      success
      issue {
        id
      }
    }
  }
`;
/** IssueCreateMutation shape. */
export interface ICreateIssueMutation {
  issueCreate: {
    success: boolean;
    issue?: {
      id: string;
    };
  };
}

/** Create new issue attachment mutation. */
export const CreateAttachmentMutation = `
  mutation IssueAttachmentMutation(
    $issueId: String!
    $title: String!
    $subtitle: String
    $url: String!
    $metadata: JSONObject
    $commentBody: String
  ) {
    attachmentCreate(input: {
      issueId: $issueId
      title: $title
      subtitle: $subtitle
      url: $url
      metadata: $metadata
      commentBody: $commentBody
    }) {
      success
      attachment {
        id
      }
    }
  }
`;

/** CreateAttachmentMutation shape. */
export interface ICreateAttachmentMutation {
  attachmentCreate: {
    success: boolean;
    attachment?: {
      id: string;
    };
  };
}
/** Delete issue attachment mutation. */
export const DeleteAttachmentMutation = `
  mutation IssueAttachmentMutation(
    $id: String!
  ) {
    attachmentDelete(id: $id) {
      success
    }
  }
`;

/** Create new project attachment mutation. */
export const CreateProjectAttachmentMutation = `
  mutation ProjectAttachmentMutation(
    $projectId: String!
    $title: String!
    $subtitle: String
    $url: String!
    $metadata: JSONObject
    $noteBody: String
  ) {
    projectAttachmentCreate(input: {
      projectId: $projectId
      title: $title
      subtitle: $subtitle
      url: $url
      metadata: $metadata
      noteBody: $noteBody
    }) {
      success
      attachment {
        id
      }
    }
  }
`;

/** CreateProjectAttachmentMutation shape. */
export interface ICreateProjectAttachmentMutation {
  projectAttachmentCreate: {
    success: boolean;
    attachment?: {
      id: string;
    };
  };
}

/** DeleteAttachmentMutation shape. */
export interface IDeleteAttachmentMutation {
  attachmentDelete: {
    success: boolean;
  };
}

/** Delete project attachment mutation. */
export const DeleteProjectAttachmentMutation = `
  mutation ProjectAttachmentMutation(
    $id: String!
  ) {
    projectAttachmentDelete(id: $id) {
      success
    }
  }
`;

export interface IDeleteProjectAttachmentMutation {
  projectAttachmentDelete: {
    success: boolean;
  };
}

/** Create new issue comment mutation. */
export const CreateCommentMutation = `
  mutation IssueCommentMutation(
    $issueId: String!
    $body: String!
  ) {
    commentCreate(input: {
      issueId: $issueId
      body: $body
    }) {
      success
      comment {
        id
      }
    }
  }
`;

/** CreateCommentMutation shape. */
export interface ICreateCommentMutation {
  commentCreate: {
    success: boolean;
    comment?: {
      id: string;
    };
  };
}

/** Query current user and their teams. */
export const ViewerQuery = `
  query {
    viewer {
      id
      name
      email
      avatarUrl
    }
    teams(last: 250) {
      nodes {
        id
        name
        displayName
      }
    }
    issuePriorityValues {
      priority
      label
    }
    templatesForIntegration(integrationType: "zendesk") {
      id
      name
      templateData
      team {
        id
        name
      }
      createdAt
      sortOrder
    }
    organization {
      customersEnabled
    }
    customerRequestsOnProjectsFlag: flag(flag: "customer-requests-on-projects"){
      value
    }
  }
`;
export interface IViewerQuery {
  viewer: User;
  teams: {
    nodes: {
      id: string;
      name: string;
      displayName: string;
    }[];
  };
  issuePriorityValues: {
    priority: number;
    label: string;
  }[];
  templatesForIntegration: {
    id: string;
    name: string;
    templateData: string;
    team?: {
      id: string;
      name: string;
    };
    createdAt: string;
    sortOrder: number;
  }[];
  organization: {
    customersEnabled: boolean;
  };
  customerRequestsOnProjectsFlag: {
    value: boolean;
  };
}

/** Get attachments by their URL. Will return both issue and project attachments. */
export const AttachmentsQuery = `
  query AttachmentQuery($ticketUrl: String!) {
    attachmentsForURL(url: $ticketUrl) {
      nodes {
        id
        createdAt
        issue {
          ${issueFragment}
        }
      }
    }
    projectAttachments(filter: {url:{eq:$ticketUrl}}) {
      nodes {
        id
        createdAt
        project {
          ${projectFragment}
        }
      }
    }
  }
`;

/** Shape of the attachment query. */
export interface IAttachmentsQuery {
  attachmentsForURL: {
    nodes: Attachment[];
  };
  projectAttachments: {
    nodes: ProjectAttachment[];
  };
}

/** Get similar issues by ticket content */
export const SimilarIssuesQuery = `
  query SimilarIssuesQuery($ticketContent: String!) {
    similarIssues(text: $ticketContent, first: 3) {
      nodes {
        issue {
          ${issueFragment}
        }
      }
    }
  }
`;

/** Shape of the similar issues query. */
export interface ISimilarIssuesQuery {
  similarIssues: {
    nodes: {
      issue: Issue;
    }[];
  };
}

/** Mutation to generate an authString that we can use to poll for completion of the OAuth flow.  */
export const oauthAuthStringChallengeMutation = `
mutation OauthAuthStringChallenge($appId: String! $scope: [String!]!) {
  oauthAuthStringChallenge(appId: $appId, scope: $scope) {
    success
    authString
  }
}
`;

/** Shape of the challenge response. */
export interface OauthAuthStringChallengePayload {
  oauthAuthStringChallenge: {
    success: boolean;
    authString: string;
  };
}

/** Mutation to get the access token given an authString. */
export const oauthAuthStringCheckMutation = `
  mutation OauthAuthStringCheck($appId: String! $authString: String!) {
    oauthAuthStringCheck(appId: $appId, authString: $authString) {
      success
      token
    }
  }
`;
/** Shape of the string check response. */
export interface OauthAuthStringCheckPayload {
  oauthAuthStringCheck: {
    success: boolean;
    token?: string;
  };
}
