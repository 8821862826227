import capitalize from "lodash/capitalize";
import TurndownService from "turndown";
import type { ZendeskConversationMessage, ZendeskTicket } from "../types";

const turndownService = new TurndownService();

/**
 * Build a markdown representation of the Zendesk conversation message.
 *
 * @param msg Zendesk conversation message.
 */
export const formatMessage = (msg: ZendeskConversationMessage): string => {
  const content = msg.message.content;
  const description = content ? turndownService.turndown(msg.message.content) || "" : "";
  const attachments = msg.attachments
    .map(
      attachment =>
        `${attachment.contentType.startsWith("image") ? "!" : ""}[${attachment.filename}](${attachment.contentUrl})\n\n`
    )
    .join("\n");

  return attachments.length > 0 ? `${description}\n\n${attachments}` : description;
};

/**
 * Build issue attachment title from the ticket.
 *
 * @param ticket Zendesk ticket
 */
export const formatTicketTitle = (ticket: ZendeskTicket): string => {
  return `${capitalize(ticket.type)} #${ticket.id}`;
};

/**
 * Build ticket URL from the ticket data.
 *
 * @param subdomain Zendesk subdomain for the given Zendesk instance
 * @param ticketId Zendesk ticket ID
 * @returns Ticket url
 */
export const formatTicketUrl = (subdomain: string, ticketId: string): string => {
  return `https://${subdomain}.zendesk.com/tickets/${ticketId}`;
};

/** Helper method to convert Zendesk data into attachment metadata. */
export function attachmentForZendeskConversation(ticket: ZendeskTicket) {
  return {
    title: formatTicketTitle(ticket),
    subtitle: ticket.subject,
    url: ticket.url,
    metadata: {
      id: ticket.id,
      title: ticket.subject,
      priority: ticket.priority,
      messages: ticket.messages,
      attributes: [
        {
          name: "Type",
          value: capitalize(ticket.type),
        },
        {
          name: "Priority",
          value: ticket.priority,
        },
      ],
    },
  };
}
