import React from "react";
import styled from "styled-components";
import { Avatar } from "@zendeskgarden/react-avatars";
import { useMutation } from "urql";
import { timeAgo } from "~/utils/timeAgoLocaleEn";
import IssueIcon from "~/icons/IssueIcon";
import type { Issue } from "~/types";
import { DeleteAttachmentMutation, type IDeleteAttachmentMutation } from "../queries";
import { Label } from "./Label";
import { FullViewPrompt } from "./FullViewPrompt";

type Props = {
  // Attachment ID
  attachmentId?: string;
  // Linear issue to be shown.
  issue: Issue;
  // Should unlinking be allowed
  allowUnlink?: boolean;
  // Callback to be executed after unlinking the attachment
  onUnlink?(): void;
};

export const IssueWidget = (props: Props) => {
  const { attachmentId, issue, allowUnlink, onUnlink } = props;
  const createdAt = new Date(issue.createdAt);
  const updatedAt = new Date(issue.updatedAt);
  const [confirmUnlink, setConfirmUnlink] = React.useState(false);

  const [_deleteAttachmentResult, deleteAttachment] = useMutation<IDeleteAttachmentMutation>(DeleteAttachmentMutation);

  const handleUnlink = (event: React.MouseEvent) => {
    event.preventDefault();
    setConfirmUnlink(true);
  };

  const processUnlink = () => {
    if (attachmentId && onUnlink) {
      setConfirmUnlink(false);
      void deleteAttachment({ id: attachmentId }).then(onUnlink);
    }
  };

  return (
    <Container>
      {confirmUnlink && (
        <FullViewPrompt
          title="Unlink this issue?"
          description="Linear issue won't be deleted."
          confirmLabel="Unlink ticket"
          onConfirm={processUnlink}
          onCancel={() => setConfirmUnlink(false)}
        />
      )}
      <Header>
        <IDContainer>
          <IssueIcon />
          <ID href={issue.url} target="_blank">
            {issue.identifier}
          </ID>
        </IDContainer>
        <div>
          {allowUnlink && (
            <>
              <Link onClick={handleUnlink} href="">
                Unlink
              </Link>
              <Separator>&middot;</Separator>
            </>
          )}

          <Link href={issue.url} target="_blank">
            View in Linear
          </Link>
        </div>
      </Header>
      <Title>{issue.title}</Title>
      <Table>
        <Row>
          <Column>Status</Column>
          <Column>{issue.state.name}</Column>
        </Row>
        <Row>
          <Column>Priority</Column>
          <Column>{issue.priorityLabel}</Column>
        </Row>
        <Row>
          <Column>Assignee</Column>
          <Column>
            {issue.assignee ? (
              <>
                {issue.assignee.avatarUrl && (
                  <StyledAvatar size="extraextrasmall">
                    <img src={issue.assignee.avatarUrl} />
                  </StyledAvatar>
                )}

                {issue.assignee.name}
              </>
            ) : (
              <EmptyValue>Unassigned</EmptyValue>
            )}
          </Column>
        </Row>
        <Row>
          <Column>Labels</Column>
          <Column>
            <Labels>
              {issue.labels.nodes.length ? (
                issue.labels.nodes.map(label => <Label label={label} key={label.id} />)
              ) : (
                <EmptyValue>No labels</EmptyValue>
              )}
            </Labels>
          </Column>
        </Row>
        {issue.cycle && (
          <Row>
            <Column>Cycle</Column>
            <Column>
              {issue.cycle.name ? `${issue.cycle.name} (Cycle ${issue.cycle.number})` : `Cycle ${issue.cycle.number}`}
            </Column>
          </Row>
        )}
        {issue.project && (
          <Row>
            <Column>Project</Column>
            <Column>{issue.project.name}</Column>
          </Row>
        )}
        <Row>
          <Column>Ticket count</Column>
          <Column>{issue.customerTicketCount}</Column>
        </Row>
      </Table>
      <Updated>Created {timeAgo.format(createdAt, "round")}</Updated>
      {issue.createdAt !== issue.updatedAt && <Updated>Updated {timeAgo.format(updatedAt, "round")}</Updated>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ID = styled.a`
  font-weight: 600;
`;

const Separator = styled.span`
  padding: 0 6px;
  color: #777777;
`;

const Link = styled.a`
  font-size: 12px;
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #d9dcde;

  > ${Column}:first-child {
    flex: 0.5;
  }
`;

const Labels = styled.div`
  display: flex;
  gap: 4px;
`;

const Updated = styled.span`
  font-size: 12px;
  color: #90959d;
`;

const EmptyValue = styled.span`
  color: #777777;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
`;

const IDContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
