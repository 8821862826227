import React, { useEffect, useReducer } from "react";
import { Button, ChevronButton, SplitButton } from "@zendeskgarden/react-buttons";
import { Item, Menu } from "@zendeskgarden/react-dropdowns";
import styled from "styled-components";
import { useQuery } from "urql";
import orderBy from "lodash/orderBy";
import { CompactIssueCard } from "~/components/CompactIssueCard";
import { LinkIssueOrProject } from "~/views/LinkIssueOrProject";
import { ProjectWidget } from "~/components/ProjectWidget";
import { IssueWidget } from "../components/IssueWidget";
import {
  AttachmentsQuery,
  type IAttachmentsQuery,
  type ISimilarIssuesQuery,
  type IViewerQuery,
  SimilarIssuesQuery,
  ViewerQuery,
} from "../queries";
import type { Issue, Project, Team, TemplateData, User, ZendeskTicket } from "../types";
import { CreateForm } from "./CreateForm";
import { LoadTicketSummary } from "./LoadTicketSummary";

type ViewMode = "main" | "create" | "link";

interface State {
  viewer?: User;
  teams?: Team[];
  teamLabels?: { [teamId: string]: { id: string; name: string }[] };
  viewMode: ViewMode;
  withSummary?: boolean;
  withTemplate?: TemplateData;
  priorities?: IViewerQuery["issuePriorityValues"];
  issue?: Issue;
  project?: Project;
  templates?: IViewerQuery["templatesForIntegration"];
}
type Action =
  | { type: "showCreate"; withSummary?: boolean; withTemplate?: TemplateData }
  | { type: "showLink"; issue?: Issue }
  | { type: "showMain" }
  | {
      type: "setData";
      teams: Team[];
      viewer: User;
      priorities: IViewerQuery["issuePriorityValues"];
      templates: IViewerQuery["templatesForIntegration"];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreate":
      return {
        ...state,
        issue: undefined,
        viewMode: "create",
        withSummary: action.withSummary,
        withTemplate: action.withTemplate,
      };
    case "showLink":
      return {
        ...state,
        issue: action.issue,
        viewMode: "link",
      };
    case "showMain":
      return {
        ...state,
        issue: undefined,
        viewMode: "main",
      };
    case "setData":
      return {
        ...state,
        issue: undefined,
        teams: action.teams,
        viewer: action.viewer,
        priorities: action.priorities,
        templates: action.templates,
      };
    default:
      return { ...state };
  }
};

type Props = {
  /** Zendesk ticket information. */
  ticket: ZendeskTicket;
  /** Callback to log the user out. */
  onLogout(): void;
};

/**
 * Main view for the Zendesk app.
 *
 * @param props The Main component props.
 */
export function Main(props: Props) {
  const { ticket, onLogout } = props;
  const [state, dispatch] = useReducer(reducer, {
    viewMode: "main",
  });

  const [viewerResult] = useQuery<IViewerQuery>({
    query: ViewerQuery,
  });
  const [attachmentResult, reexecuteAttachmentQuery] = useQuery<IAttachmentsQuery>({
    query: AttachmentsQuery,
    variables: {
      ticketUrl: ticket.url,
    },
  });
  const fetching = viewerResult.fetching || attachmentResult.fetching;
  const error =
    viewerResult.error || (attachmentResult.error && !attachmentResult.error.message.includes("Entity not found"))
      ? attachmentResult.error
      : undefined;
  const attachments = attachmentResult.data?.attachmentsForURL.nodes || [];
  const projectAttachments = attachmentResult.data?.projectAttachments.nodes || [];
  const combinedAttachments = [...attachments, ...projectAttachments].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    onLogout();
  };
  const handleFetchAttachments = () => {
    reexecuteAttachmentQuery({ requestPolicy: "network-only" });
    dispatch({ type: "showMain" });
  };

  useEffect(() => {
    if (viewerResult.data) {
      viewerResult.data.teams.nodes.sort((a, b) => a.displayName.localeCompare(b.displayName));
      dispatch({
        type: "setData",
        teams: viewerResult.data.teams.nodes,
        viewer: viewerResult.data.viewer,
        priorities: viewerResult.data.issuePriorityValues,
        templates: viewerResult.data.templatesForIntegration,
      });
    }
  }, [viewerResult]);

  if (fetching) {
    return <></>;
  }
  if (error) {
    return (
      <Container>
        <p>Error occurred: {error.message}</p>
        <Separator />
        <Footer>
          {state.viewer?.name ? <>Logged in as {state.viewer.name}" &middot; </> : null}
          <a href="" onClick={handleLogout}>
            Log out
          </a>
        </Footer>
      </Container>
    );
  }

  const { viewMode, teams, priorities } = state;
  if (viewMode === "create" && teams && priorities) {
    return state.withSummary ? (
      <LoadTicketSummary body={getTicketBody(ticket)}>
        {({ title, description }) => (
          <CreateForm
            title={title}
            description={description}
            teams={teams}
            priorities={priorities}
            ticket={ticket}
            onCreate={handleFetchAttachments}
            onCancel={() => dispatch({ type: "showMain" })}
          />
        )}
      </LoadTicketSummary>
    ) : state.withTemplate ? (
      <CreateForm
        teams={teams}
        priorities={priorities}
        ticket={ticket}
        templateData={state.withTemplate}
        onCreate={handleFetchAttachments}
        onCancel={() => dispatch({ type: "showMain" })}
      />
    ) : (
      <CreateForm
        teams={teams}
        priorities={priorities}
        ticket={ticket}
        onCreate={handleFetchAttachments}
        onCancel={() => dispatch({ type: "showMain" })}
      />
    );
  }

  const canLinkProjects =
    viewerResult.data?.organization.customersEnabled && viewerResult.data?.customerRequestsOnProjectsFlag.value;

  if (state.viewMode === "link" && state.teams && state.priorities) {
    return (
      <LinkIssueOrProject
        ticket={ticket}
        onLink={handleFetchAttachments}
        onCancel={() => dispatch({ type: "showMain" })}
        issue={state.issue}
        project={state.project}
        canLinkProjects={canLinkProjects}
      />
    );
  }

  const CreateButton = () => (
    <StyledButton isStretched onClick={() => dispatch({ type: "showCreate" })}>
      <StyledCreateIcon />
      Create Issue
    </StyledButton>
  );

  return (
    <Container>
      <Buttons>
        {state.templates && state.templates.length > 0 ? (
          <SplitButton style={{ width: "100%" }}>
            <CreateButton />
            <Templates templates={state.templates} dispatch={dispatch} />
          </SplitButton>
        ) : (
          <CreateButton />
        )}
        {state.viewer?.featureFlags?.includes("support-ai-summary") && (
          <StyledButton isStretched onClick={() => dispatch({ type: "showCreate", withSummary: true })}>
            <StyledSummaryIcon />
            Create with summary
          </StyledButton>
        )}
        <StyledButton isStretched onClick={() => dispatch({ type: "showLink" })}>
          <StyledLinkIcon />
          {canLinkProjects ? "Link Issue or Project" : "Link Issue"}
        </StyledButton>
      </Buttons>
      {combinedAttachments && combinedAttachments.length > 0 ? (
        <>
          <Separator />
          <Attachments>
            {combinedAttachments.map(attachment =>
              "issue" in attachment ? (
                <IssueWidget
                  key={attachment.id}
                  attachmentId={attachment.id}
                  issue={attachment.issue}
                  onUnlink={handleFetchAttachments}
                  allowUnlink
                />
              ) : (
                <ProjectWidget
                  key={attachment.id}
                  attachmentId={attachment.id}
                  project={attachment.project}
                  onUnlink={handleFetchAttachments}
                  allowUnlink
                />
              )
            )}
          </Attachments>
        </>
      ) : (
        <SimilarIssues ticket={ticket} dispatch={dispatch} />
      )}
      <Separator />
      <Footer>
        Logged in as {state.viewer?.name} &middot;{" "}
        <a href="" onClick={handleLogout}>
          Log out
        </a>
      </Footer>
    </Container>
  );
}

function getTicketBody(ticket: ZendeskTicket) {
  const body = ticket.messages[0]?.body || "";
  return body.trim();
}

const Templates = (props: { templates: IViewerQuery["templatesForIntegration"]; dispatch: React.Dispatch<Action> }) => {
  const { templates, dispatch } = props;
  return (
    <Menu button={buttonProps => <ChevronButton aria-label="create from templates" {...buttonProps} />}>
      {orderBy(templates, [t => t.team?.name, "sortOrder", "createdAt"], ["asc", "asc", "asc"]).map(template => (
        <Item
          key={template.id}
          value={template.id}
          onClick={() => {
            dispatch({
              type: "showCreate",
              withTemplate: { id: template.id, teamId: template.team?.id, ...JSON.parse(template.templateData) },
            });
          }}
        >
          {template.name}
        </Item>
      ))}
    </Menu>
  );
};

const SimilarIssues = (props: { ticket: ZendeskTicket; dispatch: React.Dispatch<Action> }) => {
  const { ticket, dispatch } = props;
  const ticketMessage = getTicketBody(ticket)
    .split("\n")
    .map(row => `> ${row}`)
    .join("\n");
  const [similarIssuesResult] = useQuery<ISimilarIssuesQuery>({
    query: SimilarIssuesQuery,
    variables: {
      ticketContent: `${ticket.subject}\n${ticketMessage}}`,
    },
  });

  const similarIssues = similarIssuesResult.data?.similarIssues.nodes.map(n => n.issue) || [];
  const fetching = similarIssuesResult.fetching;
  if (fetching || !similarIssues || similarIssues.length === 0) {
    return <></>;
  }

  return (
    <>
      <Separator />
      <Title>Similar Issues</Title>
      <SimilarIssuesContainer>
        {similarIssues.map(i => (
          <CompactIssueCard key={i.id} issue={i} onLink={() => dispatch({ type: "showLink", issue: i })} />
        ))}
      </SimilarIssuesContainer>
    </>
  );
};

const CreateIcon = (props: {}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.75 1.00007C1.67893 1.00007 0 2.679 0 4.75007V10.2501C0 12.3211 1.67893 14.0001 3.75 14.0001H9.25009C11.3212 14.0001 13.0001 12.3211 13.0001 10.2501V7.00007C13.0001 6.58586 12.6643 6.25007 12.2501 6.25007C11.8359 6.25007 11.5001 6.58586 11.5001 7.00007V10.2501C11.5001 11.4927 10.4927 12.5001 9.25009 12.5001H3.75C2.50736 12.5001 1.5 11.4927 1.5 10.2501V4.75007C1.5 3.50743 2.50736 2.50007 3.75 2.50007H7C7.41421 2.50007 7.75 2.16428 7.75 1.75007C7.75 1.33586 7.41421 1.00007 7 1.00007H3.75Z" />
      <path d="M12.4559 0.45679C12.2663 0.393563 12.0571 0.44293 11.9158 0.584309L10.7803 1.71974L12.1945 3.13395L13.33 1.99852C13.4714 1.85714 13.5207 1.64802 13.4575 1.45834C13.2999 0.985473 12.9288 0.614413 12.4559 0.45679Z" />
      <path d="M11.1339 4.19461L9.71967 2.7804L5.52811 6.97196C4.77184 7.72823 4.25634 8.69144 4.04659 9.7402C4.03143 9.81601 4.09827 9.88286 4.17408 9.86769C5.22284 9.65794 6.18605 9.14245 6.94232 8.38618L11.1339 4.19461Z" />
    </svg>
  );
};
const SummaryIcon = (props: {}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.75 0.999893C1.67893 0.999893 0 2.67882 0 4.74989V10.2499C0 12.321 1.67893 13.9999 3.75 13.9999H9.25009C11.3212 13.9999 13.0001 12.321 13.0001 10.2499V7.99989C13.0001 7.58568 12.6643 7.24989 12.2501 7.24989C11.8359 7.24989 11.5001 7.58568 11.5001 7.99989V10.2499C11.5001 11.4925 10.4927 12.4999 9.25009 12.4999H3.75C2.50736 12.4999 1.5 11.4925 1.5 10.2499V4.74989C1.5 3.50725 2.50736 2.49989 3.75 2.49989H5C5.41421 2.49989 5.75 2.16411 5.75 1.74989C5.75 1.33568 5.41421 0.999893 5 0.999893H3.75Z" />
      <path d="M5.74691 6.51033L6.12672 5.4975C6.25649 5.15143 6.74601 5.15143 6.87578 5.4975L7.25559 6.51033C7.29615 6.6185 7.3815 6.70385 7.48967 6.74441L8.5025 7.12422C8.84857 7.25399 8.84857 7.74351 8.5025 7.87328L7.48967 8.25309C7.3815 8.29366 7.29615 8.379 7.25559 8.48717L6.87578 9.5C6.74601 9.84607 6.25649 9.84607 6.12672 9.5L5.74691 8.48717C5.70634 8.379 5.62099 8.29366 5.51283 8.25309L4.5 7.87328C4.15393 7.74351 4.15393 7.25399 4.5 7.12422L5.51283 6.74441C5.62099 6.70385 5.70634 6.6185 5.74691 6.51033Z" />
      <path d="M9.47418 2.2401L10.1267 0.5C10.2565 0.153933 10.746 0.153933 10.8758 0.5L11.5283 2.2401C11.5689 2.34827 11.6542 2.43362 11.7624 2.47418L13.5025 3.12672C13.8486 3.25649 13.8486 3.74601 13.5025 3.87578L11.7624 4.52832C11.6542 4.56888 11.5689 4.65423 11.5283 4.7624L10.8758 6.5025C10.746 6.84857 10.2565 6.84857 10.1267 6.5025L9.47418 4.7624C9.43362 4.65423 9.34827 4.56888 9.2401 4.52832L7.5 3.87578C7.15393 3.74601 7.15393 3.25649 7.5 3.12672L9.2401 2.47418C9.34827 2.43362 9.43362 2.34827 9.47418 2.2401Z" />
    </svg>
  );
};

const LinkIcon = (props: {}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path d="M3.75 0.999974C1.67893 0.999974 0 2.67891 0 4.74997V10.25C0 12.321 1.67893 14 3.75 14H9.25009C11.3212 14 13.0001 12.321 13.0001 10.25V8.99997C13.0001 8.58576 12.6643 8.24997 12.2501 8.24997C11.8359 8.24997 11.5001 8.58576 11.5001 8.99997V10.25C11.5001 11.4926 10.4927 12.5 9.25009 12.5H3.75C2.50736 12.5 1.5 11.4926 1.5 10.25V4.74997C1.5 3.50733 2.50736 2.49997 3.75 2.49997H5C5.41421 2.49997 5.75 2.16419 5.75 1.74997C5.75 1.33576 5.41421 0.999974 5 0.999974H3.75Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41889 1.03317C9.56282 -0.110762 11.4117 -0.00929028 12.5464 1.12546C13.6812 2.2602 13.7827 4.10908 12.6387 5.25301L11.1514 6.74032C10.8585 7.03321 10.3837 7.03321 10.0908 6.74032C9.79787 6.44742 9.79787 5.97255 10.0908 5.67966L11.5781 4.19235C12.077 3.69345 12.0958 2.79614 11.4858 2.18612C10.8758 1.5761 9.97845 1.59493 9.47955 2.09383L7.99224 3.58114C7.69935 3.87403 7.22448 3.87403 6.93158 3.58114C6.63869 3.28824 6.63869 2.81337 6.93158 2.52048L8.41889 1.03317ZM10.0676 3.6043C10.3605 3.89719 10.3605 4.37206 10.0676 4.66496L7.58876 7.1438C7.29587 7.43669 6.82099 7.43669 6.5281 7.1438C6.23521 6.8509 6.23521 6.37603 6.5281 6.08314L9.00694 3.6043C9.29984 3.3114 9.77471 3.3114 10.0676 3.6043ZM6.50494 4.00778C6.79783 4.30067 6.79783 4.77555 6.50494 5.06844L5.01764 6.55575C4.51874 7.05464 4.4999 7.95196 5.10992 8.56198C5.71994 9.172 6.61726 9.15316 7.11615 8.65426L8.60346 7.16696C8.89635 6.87407 9.37123 6.87407 9.66412 7.16696C9.95701 7.45985 9.95701 7.93473 9.66412 8.22762L8.17681 9.71493C7.03288 10.8589 5.18401 10.7574 4.04926 9.62264C2.91451 8.48789 2.81304 6.63902 3.95698 5.49508L5.44428 4.00778C5.73717 3.71489 6.21205 3.71489 6.50494 4.00778Z"
      />
    </svg>
  );
};

const Container = styled.div`
  padding: 12px 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  column-gap: 16px;
`;

const StyledButton = styled(Button)`
  justify-content: flex-start;
`;

const StyledCreateIcon = styled(CreateIcon)`
  fill: #3971b2;
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

const StyledSummaryIcon = styled(SummaryIcon)`
  fill: #3971b2;
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

const StyledLinkIcon = styled(LinkIcon)`
  fill: #3971b2;
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

const Separator = styled.div`
  margin: 18px 0 12px;
  border-bottom: 1px solid #d9dcde;
`;

const Footer = styled.div`
  color: #777777;
  font-size: 12px;
`;

const Attachments = styled("div")`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
`;

const SimilarIssuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
