import React from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import { timeAgo } from "~/utils/timeAgoLocaleEn";
import ProjectIcon from "~/icons/ProjectIcon";
import type { Project } from "~/types";
import { DeleteProjectAttachmentMutation, type IDeleteProjectAttachmentMutation } from "../queries";
import { FullViewPrompt } from "./FullViewPrompt";

type Props = {
  /** The ID of the Project attachment. */
  attachmentId?: string;
  /** The project to be shown. */
  project: Project;
  /** Whether unlinking is allowed. */
  allowUnlink?: boolean;
  /** Callback to be executed after unlinking the attachment. */
  onUnlink?(): void;
};

export const ProjectWidget = (props: Props) => {
  const { attachmentId, project, allowUnlink, onUnlink } = props;
  const createdAt = new Date(project.createdAt);
  const updatedAt = new Date(project.updatedAt);
  const [confirmUnlink, setConfirmUnlink] = React.useState(false);

  const [_deleteAttachmentResult, deleteAttachment] = useMutation<IDeleteProjectAttachmentMutation>(
    DeleteProjectAttachmentMutation
  );

  const handleUnlink = (event: React.MouseEvent) => {
    event.preventDefault();
    setConfirmUnlink(true);
  };

  const processUnlink = () => {
    if (attachmentId && onUnlink) {
      setConfirmUnlink(false);
      void deleteAttachment({ id: attachmentId }).then(onUnlink);
    }
  };

  return (
    <Container>
      {confirmUnlink && (
        <FullViewPrompt
          title="Unlink this project?"
          description="Linear project won't be deleted."
          confirmLabel="Unlink project"
          onConfirm={processUnlink}
          onCancel={() => setConfirmUnlink(false)}
        />
      )}
      <Header>
        <IDContainer>
          <ProjectIcon />
          <ID href={project.url} target="_blank">
            {project.name}
          </ID>
        </IDContainer>
        <div>
          {allowUnlink && (
            <>
              <Link onClick={handleUnlink} href="">
                Unlink
              </Link>
              <Separator>&middot;</Separator>
            </>
          )}

          <Link href={project.url} target="_blank">
            View in Linear
          </Link>
        </div>
      </Header>
      <Title>{project.name}</Title>
      <Table>
        <Row>
          <Column>Status</Column>
          <Column>{project.status.name}</Column>
        </Row>
        <Row>
          <Column>Priority</Column>
          <Column>{project.priorityLabel}</Column>
        </Row>
      </Table>
      <Updated>Created {timeAgo.format(createdAt, "round")}</Updated>
      {project.createdAt !== project.updatedAt && <Updated>Updated {timeAgo.format(updatedAt, "round")}</Updated>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ID = styled.a`
  font-weight: 600;
`;

const Separator = styled.span`
  padding: 0 6px;
  color: #777777;
`;

const Link = styled.a`
  font-size: 12px;
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #d9dcde;

  > ${Column}:first-child {
    flex: 0.5;
  }
`;

const Updated = styled.span`
  font-size: 12px;
  color: #90959d;
`;

const IDContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
