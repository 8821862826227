import React from "react";
import styled from "styled-components";
import { Textarea, Toggle } from "@zendeskgarden/react-forms";
import { Field, Combobox, Option, OptGroup } from "@zendeskgarden/react-dropdowns";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "urql";
import { Button } from "@zendeskgarden/react-buttons";
import throttle from "lodash/throttle";
import ProjectIcon from "~/icons/ProjectIcon";
import { ProjectWidget } from "~/components/ProjectWidget";
import IssueIcon from "~/icons/IssueIcon";
import {
  CreateAttachmentMutation,
  CreateCommentMutation,
  type ICreateAttachmentMutation,
  type ICreateCommentMutation,
  type ICombinedSearchQuery,
  SearchIssueQuery,
  CombinedSearchQuery,
  type ICreateProjectAttachmentMutation,
  CreateProjectAttachmentMutation,
} from "../queries";
import type { Issue, Project, ZendeskTicket } from "../types";
import { Constants } from "../constants";
import { attachmentForZendeskConversation } from "../utils/formattingUtils";
import { SearchIcon } from "../components/SearchIcon";
import { IssueWidget } from "../components/IssueWidget";

const LinkIssueOrProjectSchema = Yup.object().shape({
  issue: Yup.object(),
  project: Yup.object(),
});

type Props = {
  canLinkProjects?: boolean;
  ticket: ZendeskTicket;
  onLink(): void;
  onCancel(): void;
  issue?: Issue;
  project?: Project;
};

interface FormValues {
  issue?: Issue;
  project?: Project;
  comment: string;
  includeComment?: boolean;
}

export function LinkIssueOrProject(props: Props) {
  const { ticket, onLink, onCancel, issue: initialIssue } = props;
  const includeComment = localStorage.getItem(Constants.commentCacheKey) === "true" || false;

  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const [createProjectAttachmentResult, createProjectAttachment] = useMutation<ICreateProjectAttachmentMutation>(
    CreateProjectAttachmentMutation
  );
  const [createCommentResult] = useMutation<ICreateCommentMutation>(CreateCommentMutation);
  const fetching =
    createAttachmentResult.fetching || createProjectAttachmentResult.fetching || createCommentResult.fetching;

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if ((!values.issue && !values.project) || fetching) {
      setSubmitting(false);
      return;
    }
    const commentBody = values.includeComment && values.comment?.length ? values.comment : undefined;

    localStorage.setItem(Constants.commentCacheKey, values.includeComment ? "true" : "false");
    if (values.issue) {
      await createAttachment({
        ...attachmentForZendeskConversation(ticket),
        issueId: values.issue.id,
        commentBody,
      });
    } else if (values.project) {
      await createProjectAttachment({
        ...attachmentForZendeskConversation(ticket),
        projectId: values.project.id,
        noteBody: commentBody,
      });
    }

    setSubmitting(false);
    onLink();
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          comment: "",
          includeComment,
          issue: initialIssue,
        }}
        validationSchema={LinkIssueOrProjectSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<FormValues>) => (
          <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
            <SearchDropdown
              canLinkProjects={props.canLinkProjects}
              onSelectIssue={value => {
                void form.setFieldValue("issue", value);
              }}
              onSelectProject={value => {
                void form.setFieldValue("project", value);
              }}
            />

            {form.values.issue && (
              <>
                <IssueWidget issue={form.values.issue} />

                <Field>
                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Toggle id="includeComment" {...field}>
                        <Field.Label htmlFor="includeComment" isRegular>
                          Include comment
                        </Field.Label>
                      </Toggle>
                    )}
                  </FormikField>
                </Field>

                {form.values.includeComment && (
                  <Field>
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <Textarea aria-label="Comment" minRows={5} maxRows={10} isCompact autoFocus {...field} />
                      )}
                    </FormikField>
                  </Field>
                )}
              </>
            )}

            {form.values.project && (
              <>
                <ProjectWidget project={form.values.project} />

                <Field>
                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Toggle id="includeComment" {...field}>
                        <Field.Label htmlFor="includeComment" isRegular>
                          Include comment
                        </Field.Label>
                      </Toggle>
                    )}
                  </FormikField>
                </Field>

                {form.values.includeComment && (
                  <Field>
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <Textarea aria-label="Comment" minRows={5} maxRows={10} isCompact autoFocus {...field} />
                      )}
                    </FormikField>
                  </Field>
                )}
              </>
            )}

            <Buttons>
              <Button
                isPrimary
                type="submit"
                disabled={(form.values.issue === undefined && form.values.project === undefined) || form.isSubmitting}
              >
                {form.isSubmitting ? "Linking…" : "Link ticket"}
              </Button>
              <Button isBasic onClick={onCancel}>
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 4px;
  max-width: 100%;
  min-height: 350px;
  overflow: auto;
`;

const StyledForm = styled(Form)`
  > div {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 12px;
  }
`;

interface SearchDropdownProps {
  canLinkProjects?: boolean;
  onSelectIssue(issue: Issue): void;
  onSelectProject(project: Project): void;
}

/**
 * Dropdown to search issues.
 */
const SearchDropdown = (props: SearchDropdownProps) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  // Throttle input
  const [throttledQuery, setThrottledQuery] = React.useState<string>("");
  const throttled = React.useRef(throttle(newValue => setThrottledQuery(newValue), 500));
  React.useEffect(() => throttled.current(searchQuery), [searchQuery]);

  const [result] = useQuery<ICombinedSearchQuery>({
    query: props.canLinkProjects ? CombinedSearchQuery : SearchIssueQuery,
    variables: { searchQuery: throttledQuery, includeComments: true },
    pause: searchQuery === undefined,
  });
  const { fetching } = result;
  const issues = (result.fetching === false && result.data?.searchIssues.nodes) || [];
  const projects = (result.fetching === false && result.data?.searchProjects?.nodes) || [];

  return (
    <Field>
      <Combobox
        inputValue={searchQuery}
        defaultActiveIndex={0}
        isAutocomplete={true}
        placeholder={props.canLinkProjects ? "Search issues or projects…" : "Search issues…"}
        onChange={change => {
          if (change.type === "input:change") {
            setSearchQuery(change.inputValue ?? "");
          } else if (change.selectionValue) {
            const issue = issues.find(i => i.id === change.selectionValue);
            const project = projects.find(p => p.id === change.selectionValue);
            if (issue) {
              props.onSelectIssue(issue);
            } else if (project) {
              props.onSelectProject(project);
            }
          }
        }}
        startIcon={<StyledSearchIcon />}
      >
        {issues.length === 0 && projects.length === 0 && (
          <Option
            isDisabled
            value={searchQuery.length ? (fetching ? "Searching…" : "No matching result found") : "Type to search…"}
          />
        )}
        {issues.length > 0 && (
          <OptGroup icon={<IssueIcon />} legend="Issues">
            {issues.map(issue => (
              <TruncatedOption key={issue.id} value={issue.id}>
                <TruncatedLabel>{`${issue.identifier} ${issue.title}`}</TruncatedLabel>
              </TruncatedOption>
            ))}
          </OptGroup>
        )}
        {projects.length > 0 && (
          <OptGroup icon={<ProjectIcon />} legend="Projects">
            {projects.map(project => (
              <TruncatedOption key={project.id} value={project.id}>
                <TruncatedLabel>{`${project.name}`}</TruncatedLabel>
              </TruncatedOption>
            ))}
          </OptGroup>
        )}
      </Combobox>
    </Field>
  );
};

const StyledSearchIcon = styled(SearchIcon)`
  width: 14px;
`;

const TruncatedLabel = styled.div`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TruncatedOption = styled(Option)`
  display: flex;
  width: 100%;
`;
